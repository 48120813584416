// Notification
import {useToast} from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// eslint-disable-next-line object-curly-newline
import {ref, computed, watch} from '@vue/composition-api'
import store from '@/store'

export default function useDiscordChannelApi() {
    // Use toast
    const toast = useToast()

    const refChannelListTable = ref(null)
    const perPage = ref(localStorage.getItem('discordPerPage') ?? 10)
    const totalChannels = ref(0)
    const currentPage = ref(1)
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    const statusFilter = ref(null)
    const teamFilter = ref(null)
    const teamList = ref(null)
    const perPageOptions = [10, 15, 20, 100]
    const channelColumns = [
        {key: 'id', sortable: true},
        {key: 'name', sortable: true},
        {key: 'team', sortable: true},
        {key: 'work', sortable: true},
        {key: 'actions'},
    ]
    const statusOptions = [
        {label: 'Work', value: '1'},
        {label: 'Fun', value: '2'},
    ]


// ------------------------------------------------
    // Get Discord Channel List
    // ------------------------------------------------


    const dataMeta = computed(() => {
        const localItemsCount = refChannelListTable.value ? refChannelListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalChannels.value,
        }
    })
    const refetchData = () => {
        localStorage.setItem('discordPerPage', perPage.value)
        refChannelListTable.value.refresh()
    }

    watch([currentPage, perPage, searchQuery, statusFilter, teamFilter], () => {
        refetchData()
    })

    const fetchChannels = (ctx, callback) => {
        let sort = isSortDirDesc.value ? `-${sortBy.value}` : sortBy.value
        store
            .dispatch('discordChannelStore/fetchChannels', {
                sort: sort,
                page: currentPage.value,
                perPage: perPage.value,
                q: searchQuery.value,
                status: statusFilter.value,
                team: teamFilter.value,
                pagination: true
            })
            .then(response => {
                const {data, total} = response.data
                callback(data)
                totalChannels.value = response.data.pagination.total
            })
            .catch(error => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                        text: 'Oops! Something went wrong!',
                    },
                })
            })
    }

    // ------------------------------------------------
    // Get Team List
    // ------------------------------------------------
    const fetchTeamList = (ctx, callback) => {
        store
            .dispatch('teamStore/fetchTeams')
            .then(response => {
                const {data} = response.data
                teamList.value = data.map(item => ({value: item.id, label: item.name}))
            })
            .catch(error => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                        text: 'Oops! Something went wrong!',
                    },
                })
            })
    }


    return {
        fetchChannels,
        channelColumns,
        perPage,
        currentPage,
        totalChannels,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refChannelListTable,
        refetchData,
        statusFilter,
        statusOptions,

        teamFilter,
        teamList,
        fetchTeamList,
    }
}
