<template>
    <div>
        <b-card no-body class="mb-0">
            <div class="m-2">
                <b-row>
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-md-0">
                        <label>Show</label>
                        <v-select v-model="perPage" :options="perPageOptions" :clearable="false"
                                  class="per-page-selector d-inline-block mx-50 ml-1"/>
                    </b-col>
                    <b-col cols="12" md="6">
                        <b-row class="d-flex align-items-center justify-content-end">
                            <b-col cols="12" md="4" class="mb-md-0 mb-2">
                                <v-select v-if="teamList" :options="teamList"
                                          :reduce="(option) => option.value" v-model="teamFilter"
                                          placeholder="Select Team" class="w-100"/>
                            </b-col>
                            <b-col cols="12" md="4" class="mb-md-0 mb-2">
                                <v-select :options="statusOptions"
                                          :reduce="(option) => option.value" v-model="statusFilter"
                                          placeholder="Select Status" class="w-100"/>
                            </b-col>
                            <b-col cols="12" md="4" class="mb-md-0 mb-2">
                                <b-form-input v-model="searchQuery" class="d-inline-block mr-1"
                                              placeholder="Search..."/>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </div>
            <b-table ref="refChannelListTable" class="position-relative" :items="fetchChannels" responsive
                     :table-class="'build-list-table table dataTable no-footer dtr-column'" striped
                     :fields="channelColumns"
                     primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="No matching records found"
                     :sort-desc.sync="isSortDirDesc">
                <template #table-busy>
                    <div class="text-center my-2">
                        <b-spinner class="d-block mx-auto mb-50"></b-spinner>
                        <strong>Loading...</strong>
                    </div>
                </template>
                <!-- Column: id -->
                <template #cell(id)="data">
                    <span class="align-text-top text-capitalize">{{ data.item.id }}</span>
                </template>
                <!-- Column: name -->
                <template #cell(name)="data">
                    <span class="align-text-top text-capitalize">{{ data.item.name }}</span>
                </template>
                <!-- Column: name -->
                <template #cell(team)="data">
                    <span class="align-text-top text-capitalize" v-if="data.item.team">{{ data.item.team.name }}</span>
                    <span class="align-text-top text-capitalize" v-else>-</span>
                </template>
                <!-- Column: Creator -->
                <template #cell(work)="data">
                    <span class="align-text-top text-capitalize">
                        <b-badge variant="success" pill class="text-capitalize" v-if="data.item.work == 1"> Work
                        </b-badge>
                        <b-badge variant="danger" pill class="text-capitalize" v-else> Fun</b-badge>
                    </span>
                </template>
                <template #cell(actions)="data">
                    <b-button variant="flat-primary" v-b-tooltip.hover.top="'Change Channel Type'" class="px-1"
                              v-on:click="changeWorkType(data.item.id)"> Change type
                    </b-button>
                </template>
            </b-table>
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col cols="12" sm="6"
                           class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}
                            entries</span>
                    </b-col>
                    <b-col cols="12" sm="6"
                           class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination v-model="currentPage" :total-rows="totalChannels" :per-page="perPage" first-number
                                      last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item"
                                      next-class="next-item">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18"/>
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18"/>
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SocialIcons from '@/components/SocialIcons.vue'
import useDiscordChannelApi from '@/composables/useDiscordChannelApi'
import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    VBTooltip,
    BSpinner
} from 'bootstrap-vue'

export default {
    components: {
        SocialIcons,
        BSpinner,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BFormGroup,
        BPagination,
        vSelect,
    },
    directives: {
        'b-tooltip': VBTooltip,
        Ripple,
    },
    mounted() {
        this.fetchTeamList()
    },
    setup() {
        const {
            fetchChannels,
            channelColumns,
            perPage,
            currentPage,
            totalChannels,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refChannelListTable,
            refetchData,
            statusFilter,
            statusOptions,
            teamFilter,
            teamList,
            fetchTeamList,
        } = useDiscordChannelApi()

        return {
            fetchChannels,
            channelColumns,
            perPage,
            currentPage,
            totalChannels,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refChannelListTable,
            refetchData,
            statusFilter,
            statusOptions,
            teamFilter,
            teamList,
            fetchTeamList,
        }
    },
    methods: {
        changeWorkType(id) {
            this.$swal.fire({
                title: 'Are you sure?',
                text: "Are you sure you want to change the channel type?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Yes, change!',
                customClass: {
                    confirmButton: 'btn btn-primary mr-1',
                    cancelButton: 'btn btn-outline-primary ms-1'
                },
                buttonsStyling: false
            }).then(result => {
                if (result.value) {
                    this.$store.dispatch('discordChannelStore/updateChannel', {
                        id: id
                    }).then(() => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: 'Successfully updated!',
                                icon: 'CheckIcon',
                                variant: 'success',
                            },
                        })
                    }).then(() => {
                        this.refetchData()
                    })
                }
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
    width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
